@use "src/theme" as *;

.content {
  @include flexRow($alignY: center);
  @media screen and (max-width: 768px) {
    display: none;
  }

  a {
    border-bottom: 4px solid transparent;
    color: $darkGray;
    padding-bottom: 8px;
    position: relative;
  }

  .active {
    color: $darkBlue;
    border-bottom-color: $darkBlue;
  }
}

.link {
  @include flexRow(center, center, nowrap);
  margin-right: 48px;
  &:hover {
    filter: brightness(0.4);
  }
}
