@use "src/theme" as *;

.article {
  @include flexRow($alignX: space-between, $alignY: center);
  margin-bottom: 40px;
  margin-top: 80px;
  padding: 0 20px;
  position: relative;

  img {
    object-fit: cover;
    width: 48%;
    position: absolute;
    height: 100%;
    @media screen and (max-width: 768px) {
      height: auto;
      position: static;
      width: 100%;
    }
  }

  .imgdiv {
    min-height: 332px;
    width: 50%;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .text {
    width: calc(50% - 40px);
    @media screen and (max-width: 768px) {
      margin-top: 32px;
      width: 100%;
    }

    p {
      margin: 16px 0 32px;
      white-space: pre-line;
    }

    .link {
      color: $link;
      font-weight: 700;
      margin-top: 32px;
      width: 100%;
      cursor: pointer;
      &:hover,
      &:focus {
        color: $linkHover;
        text-decoration: underline;
      }
    }
  }
}
