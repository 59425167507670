@use "src/theme" as *;

.root {
  background-color: $warmWhite;
}

.content {
  @media screen and (max-width: 768px) {
    padding: 12px 20px;
  }
}

.header {
  @include flexColumn();
  position: relative;
  width: 100%;
  @media screen and (max-width: 768px) {
    align-items: center;
  }
  @media screen and (max-width: 475px) {
    height: 40px;
  }

  .logo {
    @include flexRow($alignY: center);
    color: $darkBlue;
    font-weight: 900;
    font-size: $fontLabel;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    @media screen and (max-width: 475px) {
      label {
        display: none;
      }
    }

    svg {
      height: 51px;
      margin: 0;
      padding-right: 40px;
      padding-top: 8px;
      max-width: 1756x;
      @media screen and (max-width: 768px) {
        padding-right: 0;
        width: 100px;
        height: auto;
      }
    }
  }

  .menu {
    bottom: 0;
    display: none;
    left: 0;
    position: absolute;
    top: 0;

    svg {
      fill: $darkGray;
      height: 20px;
      margin: auto 0;
      width: 24px;
    }
    @media screen and (max-width: 768px) {
      display: flex;
    }
  }
}

.links {
  @include flexRow($alignX: space-between, $alignY: center);
  padding-top: 20px;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }

  a {
    font-size: $fontLabel;
    line-height: 18px;
  }
}
