@use "src/theme" as *;

.root {
  position: relative;
}

.container {
  @include flexColumn();
  padding: 0;
}

.banner {
  @include flexRow($alignY: center);
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }

  img {
    object-fit: cover;
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    @media screen and (max-width: 768px) {
      height: auto;
      position: static;
      width: 100%;
    }
  }

  .imgdiv {
    min-height: 332px;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .text {
    @include flexColumn();
    padding: 20px;
    padding-right: 100px;
    width: 48%;
    @media screen and (max-width: 1023px) {
      padding-right: 20px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 475px) {
      a {
        width: 100%;
      }
    }

    button,
    label {
      margin-top: 16px;
    }
  }
}

.reverse {
  flex-direction: row-reverse;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }

  img {
    right: unset;
    left: 0;
  }
}
