//Typography
$fontFamily: "Enterprise Sans", "Arial", sans-serif;
$fontHeading1: 52px;
$fontHeading2: 34px;
$fontHeading3: 24px;
$fontBody: 20px;
$fontSubBody: 17px;
$fontLabel: 14px;

//Color Palette
$optumOrange: var(--palette-primary, #ff612b);
$optumOrangeHover: var(--palette-primary-hover, #eb4e1a);
$white: var(--palette-light, #ffffff);
$sky: var(--palette-gray, #d9f6fa);
$warmWhite: var(--palette-gray, #faf8f2);
$darkBlue: var(--palette-gray, #002677);
$darkGray: var(--palette-gray, #4b4d4f);
$link: var(--palette-link, #196ecf);
$linkHover: var(--palette-link-hover, #0a3d77);

//Defaults
$borderColor: #e0e0e0;
$buttonBGColor: $darkBlue;
$buttonColor: $white;
$buttonHover: #1a52cc;
$footerBGColor: $warmWhite;
$shadow: rgba(0, 0, 0, 35%);

$h1-font-sizes: (
  null: 52px,
  768px: 40px,
);

$h2-font-sizes: (
  null: 34px,
  768px: 30px,
);

$h3-font-sizes: (
  null: 24px,
);
