@use "src/theme" as *;

.root {
  @include flexColumn(center, center);
  width: 100%;
}

.content {
  @include flexColumn();
  margin: 0 auto;
  max-width: 1246px;
  padding: 20px;
  width: 100%;
}
