@use "src/theme" as *;

a {
  color: $link;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  &:hover,
  &:focus-visible {
    color: $linkHover;
    svg {
      fill: $linkHover;
    }
  }

  svg {
    fill: $link;
    height: 10px;
    margin-left: 4px;
  }
}

.button {
  position: relative;
  background-color: $buttonBGColor;
  border-radius: 28px;
  border: none;
  color: $buttonColor;
  cursor: pointer;
  min-height: 44px;
  font-family: $fontFamily;
  font-size: $fontSubBody;
  font-weight: 700;
  margin-bottom: 8px;
  min-width: 200px;
  outline: none;
  padding: 4px 32px 8px;
  transition: all ease-in-out 0.2s;
  // white-space: nowrap;
  @media screen and (max-width: 475px) {
    width: 100%;
  }

  &:hover,
  &:focus {
    background-color: $buttonHover;
  }

  &:disabled {
    background-color: $darkGray;
    cursor: default;
  }
}

.scrollTop {
  @include flexColumn(center, center);
  background-color: $darkGray;
  border-radius: 50%;
  bottom: 40px;
  cursor: pointer;
  height: 48px;
  opacity: 0;
  position: fixed;
  right: 60px;
  transition: opacity 0.4s ease-in-out;
  visibility: hidden;
  width: 48px;
  z-index: 3;
  @media screen and (max-width: 650px) {
    right: 40px;
  }

  svg {
    height: auto;
    padding-top: 4px;
    transform: rotate(180deg);
    width: 40px;
    path {
      fill: $warmWhite;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
