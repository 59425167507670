@use "variables" as *;

//Mixins
@mixin flex(
  $direction: column,
  $justifyContent: flex-start,
  $alignItems: flex-start
) {
  align-items: $alignItems;
  box-sizing: border-box;
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
}

@mixin flexRow($alignX: flex-start, $alignY: flex-start, $wrap: wrap) {
  @include flex(
    $direction: row,
    $justifyContent: $alignX,
    $alignItems: $alignY
  );
  flex-wrap: $wrap;
}

@mixin flexColumn($alignX: flex-start, $alignY: flex-start) {
  @include flex($justifyContent: $alignY, $alignItems: $alignX);
}

@mixin pageCenter() {
  @include flexColumn(center, center);
  margin: 12px auto;
  max-width: 1292px;
  padding: 0 20px;
  text-align: center;
}

@mixin pageHalfContainer() {
  @include flexRow($alignX: space-evenly);
  align-items: center;
  width: 100%;
}

@mixin fade() {
  animation: fadeIn 0.5s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
      line-height: calc($fs-font-size + 6px);
    } @else {
      @media screen and (max-width: $fs-breakpoint) {
        font-size: $fs-font-size;
        line-height: calc($fs-font-size + 6px);
      }
    }
  }
}
