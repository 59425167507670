@use "src/theme" as *;

.content {
  align-items: center;
  padding-bottom: 48px;
  padding-top: 80px;

  p {
    margin-top: 16px;
    text-align: left;
    white-space: pre-line;
    @media screen and (max-width: 550px) {
      font-size: $fontBody;
    }
  }
}

.warm {
  background-color: $warmWhite;
}

.top {
  @include flexRow($alignY: center, $alignX: center);
  margin-bottom: 60px;
  width: 100%;

  @media screen and (max-width: 980px) {
    justify-content: center;
    margin-top: 20px;
  }

  img {
    // flex: 1 1 350px;
    object-fit: contain;
    margin-left: 80px;
    @media screen and (max-width: 980px) {
      margin: 20px 0 0;
    }
    @media screen and (max-width: 550px) {
      width: 300px;
      height: auto;
    }
  }
}

.reverse {
  flex-direction: row-reverse;

  img {
    margin-left: 0;
    margin-right: 80px;
    @media screen and (max-width: 980px) {
      margin: 20px 0 0;
    }
  }
}

.text {
  @include flexColumn($alignX: center);
  text-align: center;
  // flex: 1 1 350px;

  svg {
    height: 80px;
    margin-bottom: 16px;
    width: 80px;
  }

  .subtitle {
    margin-top: 16px;
  }

  .buttons {
    @include flexRow();
    margin: 20px auto 0;
    @media screen and (max-width: 550px) {
      justify-content: center;
    }
    @media screen and (max-width: 475px) {
      a {
        width: 100%;
      }
    }

    button {
      margin: 12px;
    }
  }
}

.body {
  margin-bottom: 32px;

  .title {
    margin-bottom: 16px;
  }
}
