@use "src/theme" as *;

.content {
  @include flexRow($alignX: space-between, $alignY: stretch);
  padding-top: 80px;
  padding-bottom: 80px;

  .body {
    font-weight: 500;
    margin: 16px 0 32px;
  }
}

.item {
  @include flexColumn($alignY: space-between);
  background-color: $sky;
  box-shadow: 0 3px 6px rgba(#000000, 0.16);
  color: $darkBlue;
  cursor: pointer;
  font-weight: 500;
  padding: 32px;
  position: relative;
  width: calc(50% - 24px);
  transition: transform 0.2s ease-in-out;
  @media screen and (max-width: 900px) {
    margin-bottom: 40px;
    width: 100%;
  }
  @media screen and (max-width: 550px) {
    padding: 20px;
  }
  &:hover,
  &:focus {
    transform: translateY(-4px);
    label {
      color: $linkHover;
      text-decoration: underline;
    }
  }

  label {
    color: $link;
    cursor: pointer;
    font-weight: 700;
    margin-top: 12px;
    width: 100%;
  }
}

.left {
  background-color: $white;
  white-space: pre-line;

  img {
    margin: 0 auto 8px;
  }
}

.right img {
  margin-bottom: 36px;
  height: auto;
  width: 100%;
}

.upper {
  @include flexColumn();
}

.lower {
  @include flexRow($alignY: center, $alignX: space-between);
  @media screen and (max-width: 768px) {
    margin-top: 12px;
  }

  .portrait {
    @include flexColumn($alignX: center);
    color: $darkGray;
    font-size: $fontLabel;
    text-align: center;
    width: 180px;
    @media screen and (max-width: 480px) {
      margin-bottom: 32px;
      width: 100%;
    }
    strong {
      color: $darkBlue;
    }
  }

  label {
    width: calc(100% - 220px);
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
}
