@use "src/theme" as *;

.home {
  @include flexColumn($alignX: center);
  width: 100%;
}

.talk {
  background-color: $sky;
  padding-bottom: 60px;
  padding-top: 60px;

  .content {
    @include flexColumn($alignX: center);
    white-space: pre-wrap;

    .text {
      margin-bottom: 32px;
    }

    .buttons {
      @include flexRow($alignX: space-between);
      margin-top: 48px;
      max-width: 720px;
      width: 100%;
      @media screen and (max-width: 475px) {
        a {
          width: 100%;
        }
      }
    }
  }
}

.start {
  padding-top: 80px;
  text-align: center;
}

.hr {
  margin: 0 auto;
  background-color: $darkBlue;
  width: calc(100% - 40px);
  height: 2px;
  max-width: 500px;
}
