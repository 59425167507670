@use "src/theme" as *;

.root {
  background-color: $sky;
  padding: 52px 0 12px;
}

.content {
  text-align: center;
  align-items: center;
}

.items {
  @include flexRow($alignX: space-between);
  font-size: $fontBody;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
  @media screen and (max-width: 1200px) and (min-width: 601px) {
    width: 600px;
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
}

.iconText {
  @include flexColumn($alignX: center);
  background-color: $white;
  border-radius: 50%;
  padding: 28px;
  margin-bottom: 20px;
  width: 286px;
  height: 286px;

  .label {
    color: $darkBlue;
    margin-bottom: 12px;
  }

  p {
    font-size: $fontSubBody;
    sup {
      font-size: 12px;
    }
  }
  svg {
    height: 60px;
    margin-bottom: 12px;
    max-width: 80px;
  }
}
