@use "src/theme" as *;

@mixin format($size: $h1-font-sizes) {
  @include font-size($size);
  color: $darkBlue;
  font-weight: 700;
}

.default {
  white-space: pre-wrap;
  width: 100%;
  @media screen and (max-width: 768px) {
    white-space: normal;
  }

  &.h1 {
    @include format();
  }

  &.h2 {
    @include format($h2-font-sizes);
  }

  &.h3 {
    @include format($h3-font-sizes);
  }
}

.centered {
  text-align: center;
}
