@use "src/theme" as *;

.visit_root {
  background-color: $sky;
}

.visit {
  @include flexRow($alignY: center, $alignX: space-between);
  padding-bottom: 32px;
  padding-top: 32px;
  @media screen and (max-width: 475px) {
    a {
      width: 100%;
    }
  }

  .text {
    width: calc(100% - 232px);
    max-width: 800px;
    @media screen and (max-width: 600px) {
      margin-bottom: 20px;
      width: 100%;
    }
  }
}

.footer_root {
  background-color: $warmWhite;
  min-height: 72px;
}

.footer {
  font-size: $fontLabel;
  padding-bottom: 32px;
  padding-top: 32px;
  white-space: pre-line;

  label {
    padding-right: 52px;
  }
}
